import * as React from 'react';
import { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HTML5Video from '../global/video.js';
import CarmarionVideo from '../../video/champions-of-change.mp4'

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined
    };
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;
    switch (video) {
      case "BlackWomenInHIV":
        VideoThumb = '../../celebrating-black-women-leaders-hero.jpg'
        VideoSrc = CarmarionVideo
        VideoTrackLabel = "Celebrating Black Women in HIV"
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel
    })
    //this.handleShow();
  }

  render() {
    return (
      <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
        <Container fluid className='hero celebrating-black-women-leaders' />
        <Container className='celebrating-black-women-leaders'>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Champions of Change</h1>
                <h1 className='title '>A Celebration of Black Women Changemakers in&nbsp;HIV</h1>
              </div>
              <p>
              Throughout history, Black women have been at the forefront of social movements and driving progress—including helping to end the HIV epidemic—while often
              being overlooked. It’s time we honor and recognize them for their exceptional leadership.
              </p>
              <p>
                In Champions of Change: A Celebration of Black Women Changemakers in HIV, we’re proud to honor ten Black
                women leaders in HIV who have been leading the way forward for decades. From community leaders
                to health researchers to HIV pioneers, the legacy and influence of these inspirational women
                can be felt every day. We honor these trailblazers, how they've paved the
                way for change in their communities and beyond, and how they're helping
                uplift the next generation of Black women leaders in HIV.
              </p>
              <img
                onClick={()=>this.handleClick("BlackWomenInHIV")}
                src={"/champions-of-change.jpg"}
                alt="Celebrating Black Women in HIV"
                className="video w-100 mb-2 mt-4"
              />
              <h2 className='title mt-4 pt-3'>Meet the Honorees</h2>
              <Container>
                <Row className='videos'>
                  <HTML5Video
                    handleClose={this.handleClose}
                    show={this.state.show}
                    thumbnail={this.state.VideoThumb}
                    url={this.state.VideoSrc}
                    videoTrackLabel={this.state.VideoTrackLabel}
                  />
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/DanielleCampbell.jpg'} />
                    <span className='title'>Danielle Campbell</span>
                    <p className='description'>
                      Danielle, a prominent health researcher, represents and creates space for women of color at the forefront of HIV research. She is passionately working to champion
                      social justice, strongly advocates for Black women to be included in HIV research, and uses research to strive for health equity.
                    </p>
                      <a className='cta-companion-article' href="/championsofchange/danielle/">
                      <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                    </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/ToriCooper.jpg'} />
                    <span className='title'>Tori Cooper</span>
                    <p className='description'>
                      Tori proudly represents the voice and intersecting needs of the Black, transgender, and HIV communities. Her advocacy is rooted
                      in educating her communities about HIV prevention and care while still
                      meeting people where they are. When faced with challenges, she believes nothing is impossible.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/tori/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/RaniyahCopeland.jpg'} />
                    <span className='title'>Raniyah Copeland</span>
                    <p className='description'>
                      As the former CEO of Black AIDS Institute and cofounder of Equity & Impact Solutions, Raniyah's work focuses on equity
                      advancement, community engagement, and culturally relevant programming—all pathways to help end HIV. She is committed to seeing that HIV does not feel inevitable or like a death sentence for Black people.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/raniyah/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/DazonDixonDiallo.jpg'} />
                    <span className='title'>Dázon Dixon Diallo</span>
                    <p className='description'>
                      Dázon was talking about HIV and sexual health with Black women before there was any research on how HIV
                      could impact them, or resources to help. For over three decades, Dázon has created numerous programs and
                      initiatives centered on giving women and women of color agency in the greater HIV community. As one
                      of the first advocates for women's sexual and reproductive health in HIV, Dázon and her organization SisterLove, Inc.,* are the blueprint
                      for so many others advocating for women in HIV.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/dazon/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/JuneGipson.jpg'} />
                    <span className='title'>June Gipson</span>
                    <p className='description'>
                      June is determined to drive change in the South, and as the CEO of My Brother’s Keeper, Inc.,* she's doing just that. She creates and
                      refines curricula for other HIV/AIDS organizations, helping them to lead with best practices, research,
                      and knowledge, and to bring care and resources into communities that need it the most.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/june/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/LeishaMcKinleyBeach.jpg'} />
                    <span className='title'>Leisha McKinley Beach</span>
                    <p className='description'>
                      Leisha, a national HIV/AIDS consultant, has worked with multiple health departments in the South and helps build programs and systems that help communities get the care
                      they need and deserve. She gains strength from her faith and leads with love to help change the data and create better health outcomes for Black people.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/leisha/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/MarleneMcNeese.jpg'} />
                    <span className='title'>Marlene McNeese</span>
                    <p className='description'>
                    As a leading voice on HIV policy and implementation, Marlene is helping to redefine public health and community-based support systems that
                      address HIV prevention. She equips communities with accessible information and programming and advocates for the hardest hit communities to have a seat at the table.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/marlene/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/KaylaQuimbley.jpg'} />
                    <span className='title'>Kayla Quimbley</span>
                    <p className='description'>
                      Kayla is a leading voice for young people living with HIV and advocates for their inclusion in the creation of HIV policies. She utilizes her lived experience to reframe HIV narratives, find and
                      create safe spaces, and helps improve access to comprehensive sexual education.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/kayla/">
                  <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/DafinaWard.jpg'} />
                    <span className='title'>Dafina Ward</span>
                    <p className='description'>
                      Dafina is an attorney, nonprofit strategist, and executive director of the Southern AIDS Coalition* and works at the intersection of HIV and other health
                      and justice issues in the South. She believes that ending the HIV epidemic in the South is crucial to helping end the HIV epidemic for all and
                      builds the capacity of Black- and Brown-led organizations to help change the course of HIV in the South.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/dafina/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <Col xs={12} lg={6} className='video-card px-0'>
                    <img src={'/LindaScruggs.jpg'} />
                    <span className='title'>Linda H. Scruggs</span>
                    <p className='description'>
                      Linda, a long-term survivor of HIV, focuses on the diverse experiences and backgrounds of women living with HIV. After overcoming trauma, she
                      fought against disempowerment, advocating for pregnant moms and children living with HIV. She develops training programs covering survival skills, literacy,
                      and advocacy to help empower these women to use their experiences to help others in need.
                    </p>
                    <a className='cta-companion-article' href="/championsofchange/linda/">
                    <span class="read-more"><span class="font-heavy color-black">Discover more</span><i class="fa fa-chevron-right "></i></span>
                  </a>
                  </Col>
                  <span class="cont-hang footnote font-16 font-light mb-3">
                  <span class="hang">*</span>These organizations are Gilead grant recipients.
                  </span>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
